@import "@sitestyles";

.main_container {
  padding: 75px 150px 150px;
  background-color: $MainHeaderBg;

  @media screen and (max-width: 1400px) {
    padding: 64px 32px;
  }

  @media screen and (max-width: 600px) {
    padding: 44px 16px 64px;
  }
}

.main_title {
  text-align: center;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  color: $mainTitleColor;
  margin-bottom: 38px;

  @media screen and (max-width: 1140px) {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.main_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 961px) {
    flex-direction: column;
    row-gap: 16px;
  }
}

.main_col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & a {
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 20px;
    color: $mainTitleColor;

    @media screen and (max-width: 1140px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
    }
  }
}