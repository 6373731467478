@import "@sitestyles";

 .main_info_block {
  padding: 75px 0;
  background: $MainHeaderBg;

  @media screen and (max-width: 1140px) {
    padding: 64px 0 64px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 44px;
    padding-top: 38px;
  }
}

.main_advantages_block {
  padding: 0 16px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}

.main_advantages_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 150px;
  @media screen and (max-width: 1140px) {
    row-gap: 90px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    row-gap: 70px;
  }
}

.main_advantages_item {
  display: flex;
  align-items: center;
  width: 492px;

  @media screen and (max-width: 1140px) {
    width: 50%;
  }
  @media screen and (max-width: 961px) {
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.main_advantages_item_img {
  margin-right: 32px;
  max-width: 120px;
  max-height: 120px;

  @media screen and (max-width: 1140px) {
    max-width: 96px;
    max-height: 96px;
  }

  @media screen and (max-width: 961px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.main_advantages_item_title {
  max-width: 340px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $mainTitleColor;
  margin-bottom: 8px;

  @media screen and (max-width: 1140px) {
    font-size: 20px;
    line-height: 28px;
    max-width: 100%;
  }

  @media screen and (max-width: 961px) {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
}

.main_advantages_item_text {
  max-width: 340px;
  font-size: 16px;
  line-height: 150%;
  color: $mainTextColor;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }

  @media screen and (max-width: 961px) {
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}