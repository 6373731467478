$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #720066;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(90.83deg, #00317B 1.1%, #720066 98.3%);
$buttonModalBackgroundColor: linear-gradient(90.83deg, #00317B 1.1%, #720066 98.3%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #00317B 0%, #720066 100%);
$profileButtonActiveColor: #00317b;
$mainFont: "Open Sans", sans-serif;
$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #00317B;
$iconResetPasswordBackgroundColor: #e8f4ff;
$iconResetPasswordFillColor: #0965b0;
$iconCoinsBalanceGradientColor: linear-gradient(90.83deg, #00317B 1.1%, #720066 98.3%);
$iconCoinsBalanceDefaultColor: linear-gradient(90.83deg, #00317B 1.1%, #720066 98.3%);
$iconLikeWomanProfileFillColor: #EB5757;
$iconAddPlusFillColor: linear-gradient(90.83deg, #00317B 1.1%, #720066 98.3%);
$iconAddPhotoFillColor: #720066;
$iconVideosFillColor: #446077;
$iconHandWaveFillColor: #446077;
$iconLetterFillColor: #446077;
$iconFilterFillColor: #446077;
$iconLikeVideoComponentFillColor: #720066;
$iconLetterVideoComponentFillColor: #720066;
$iconMoveFillColor: #00317B;
$iconUploadAttachmentFillColor: #2b5a64;
$iconArrowLeftFillColor: #2b5a64;
$iconsMoreInfoChatHeaderFillColor: #720066;
$iconProfileFillColor: #720066;
$iconCrossCloseFillColor: #00317B;
$iconReplyFillColor: #720066;

$supportFeedbackModalHeaderBgColor: rgba(0, 49, 123, 0.1);
$supportFeedbackRadioLabelColor: #06307B;
$supportFeedbackRadioLabelColorChecked: #720066;
$supportFeedbackRadioLabelColorHover: #7200664D;
$supportFeedbackRatingTextColor: #446077;
$supportFeedbackTitleColor: #00317B;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #00317B;

$inputBorderNormal: #9AAFD0;
$inputBorderHover: #9AAFD0;
$inputBorderActive: #9AAFD0;

$selectBackgroundColor: #EFF3F8;
$selectBorderColor: #9AAFD0;
$selectBorderHoverColor: #5577AD;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #F5F7FA;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #9AAFD0;
$textareaBorderActiveColor: #720066;
$textareaOutlineColor: #7200661a;

$messageRightBackground: rgba(210, 223, 245, 0.9);
$messageLeftBackground: #F3F4FC;

$chatTextareaBg: #EEF1FE;

$attachTouchText: #6F0267;

$bluePinkColor: #446077;

$uploadTabsColor: #2b5a64;
$uploadTabsActiveColor: #720066;

$uploadTabsItemBg: #F3F5FF;

$uploadTabsButton: #2b5a64;

$mediaListTabActiveBg: #EFF5FE;
$mediaListTabActiveColor: #093A83;

$mediaListSwitcherActiveBg: #e0eaf5;

$searchBottomBtn: #1C2677;

$buttonHoverBg: #E3E7FF;
$buttonHoverBg2: #eaecfa;
$buttonHoverBg3: #EEF1FE;

$lettersMenuIconColor: #3664A1;

$videoButtonsColor: #700167;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #9D9DDE;

$unreadLetter: #e3ecfc;

//LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0, 0, 0, .2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317b;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$attachIcoBlue: url(../../img/attach-ico-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #06307B;
$npsRadioLabelColorChecked: #720066;
$npsRadioLabelColorHover: #7200664D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #9AAFD0;
$npsCheckboxColor: #9C6EF7;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #00317b;

//POLICY
$policyTitleColor: #00317B;
$policyTextColor: #446077;
$policyButtonBg: #E3ECFC;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #ACBAFF;

@import 'theme';
